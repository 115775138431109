<template>
    <div class="background full-height full-width login-image" :style="backgroundImage">
        <div class="app-user">
            <div class="user-top">
                <v-container class="my-10">
                    <v-row>
                        <v-col cols="12">
                            <!-- <img class="login-logo" src="@/assets/logo-dark.png" /> -->
                            <img class="login-logo" :src="getImage(orgData.data.logoDark)" />
                        </v-col>

                        <v-col cols="12" sm="8" offset-sm="2">
                            <h4 class="text-center white--text mb-6">
                                Log in or Sign Up to Enter the {{ orgData.title }}
                            </h4>

                            <div class="mx-auto d-flex align-center justify-center">
                                <v-btn :href="`${orgData.data.links.homepage}`" small text color="white">
                                    <span class="text-capitalize font-weight-regular d-flex align-center">
                                        <v-icon class="mr-2 white--text" small>mdi-open-in-new</v-icon>
                                        <b>Back to website</b>
                                    </span>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <div class="user-bottom dialog-row">
                <transition name="slide-y-reverse-transition" mode="out-in" appear>
                    <router-view />
                </transition>

                <div class="footer-row pa-5">
                    <p class="caption ma-0 x-small--text">&copy; {{ year }} {{ orgData.title }}</p>
                </div>
                <div class="footer-row pa-2">
                    <p class="caption ma-0 x-small--text">Powered by Prize Art Software</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "user",
    data() {
        return {
            background: null,
        };
    },
    computed: {
        orgData() {
            console.log("STORE", this.$store.getters["org/getOrgData"]);
            return this.$store.getters["org/getOrgData"];
        },
        backgroundImage() {
            let image = this.background;
            return {
                backgroundImage: `url(${image})`,
            };
        },
        year() {
            return new Date().getFullYear();
        },
    },
    watch: {
        // watching for changes in orgData
        orgData: {
            immediate: true, // run the handler immediately after the watcher is activated
            handler(newVal) {
                this.background = this.getImage(newVal.data.background);
            },
        },
    },
    methods: {
        getImage(image) {
            if (image) {
                return this.$fluro.asset.imageUrl(image._id);
            }
        },
    },
    mounted() {
        console.log(this.orgData.data);
        this.background = this.getImage(this.orgData.data.background);
    },
};
</script>

<style lang="scss">
.login-image {
    background-size: cover;
}

.app-user {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    margin: auto;
    overflow-y: scroll;

    .login-logo {
        display: block;
        margin: 0 auto;
        max-width: 200px;
        width: 100%;
    }

    .user-top {
        margin-bottom: 20px;
    }

    .user-bottom {
        transform: translateY(-40px);
    }

    .dialog-row {
        z-index: 1;
        position: relative;
        background: var(--v-background-base); /* semi-transparent white */
        backdrop-filter: blur(10px); /* blur effect */
        border-radius: 4px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.18);
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
    }

    .footer-row {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 1;
        pointer-events: none;
    }
}
</style>
